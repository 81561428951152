/* overlay4.css */

.overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5); /* Example for overlay background */
    color: #fff; /* Example for text color */
  }
  
  .overlay h1 {
    margin-bottom: 20px;
  }
  
  .overlay > form {
    max-width: 600px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .overlay > form > input,
  .overlay > form > textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .overlay > form > button {
    width: auto;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #5c6bc0;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .overlay > form > button:hover {
    background-color: #3f51b5;
  }
  
  .overlay p {
    text-align: center;
  }
  
  .overlay > p:nth-child(2) > a:nth-child(1) > img:nth-child(1) {
    fill: white;
    width: 60px;
    height: 60px;
  }

  .overlay > p:nth-child(2) > a:nth-child(1) > img:nth-child(1) {
    fill: white;
    width: 60px;
    height: 60px;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .overlay > form {
      width: 90%;
    }
  }
  