/* overlay2.css */

.overlay2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  position: inherit;
  width: 100%;
  height: 100%;
  position: inherit; /* Fixed positioning to cover the entire viewport */
  top: 0;
  left: 0;
  backdrop-filter: blur(5px); /* Apply the frosted glass effect */
  z-index: 10; /* Ensure it's above the background image but below other content */
  overflow: auto;
}

/* Style for individual project cards */
.project-card {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  width: auto; /* Fixed width for each card */
  margin-bottom: 30px; /* Space between cards vertically */
  background: white; /* Assuming a light theme, adjust the color as needed */
  border-radius: 8px; /* Rounded corners for the cards */
  overflow: visible; /* Ensures that the border radius clips the content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease; /* Smooth transition for hover effects */
}
@media (min-width: 600px) {
  .overlay2 {
    flex-direction: row; /* Stack items horizontally on larger screens */
  }
}

.project-card:hover {
  transform: translateY(-5px); /* Slightly raise the card on hover */
}

/* Style for the project title */
.project-title {
  font-family: 'Roboto', sans-serif; /* Example font */
  font-size: 24px; /* Title size */
  color: #333; /* Title color */
  margin: 16px 0; /* Space above and below the title */
}

/* Style for the image container */
.project-image-container {
  width: 100%; /* The image container takes the full width of the card */
}

/* Style for the images themselves */
.project-image {
  width: 100%; /* Image takes the full width of its container */
  display: block; /* Removes any extra space below the image */
  height: auto; /* Maintain aspect ratio */
}

/* Style for the additional content that appears after first hover */
.project-additional-content {
  padding: 10px;
  text-align: center;
  width: 100%; /* Take up the full width of the card */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* This media query is for devices with a width less than or equal to 768px,
   which typically includes tablets and mobile devices in portrait orientation */
   @media only screen and (max-width: 768px) {
    .project-container {
      width: 70%; /* Set the width of the project containers to 70% */
      margin: 0 auto; /* Center the containers in the viewport */
      /* Adjust padding, margins, and other properties as needed */
    }
  
    /* Ensure the image within the project container is also responsive */
    .project-img-box {
      width: 100%; /* Make the image container take the full width of the project container */
      height: auto; /* Maintain the aspect ratio of the content */
    }
  
    .project-image {
      max-width: 100%; /* Make the image responsive */
      height: auto; /* Maintain the aspect ratio of the image */
    }
  
    /* Add any additional styles you need to adjust for this viewport size */
  }