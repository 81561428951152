.project {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.project:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* project.css */
.project-img-box {
  position: relative;
  width: 100%;
  height: 200px; /* Set a specific height */
  margin: 10px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* Media query for screens larger than 600px */
@media (min-width: 600px) {
  .project-img-box {
    width: 350px; /* Set a fixed width for larger screens */
    height: 200px; /* Set a fixed height for larger screens */
  }
}

.project-media {
  width: 100%;
  height: 100%; /* Make the media fill its container */
  object-fit: cover; /* Maintain aspect ratio */
}

.project-img-box img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease; /* Smooth transition for the image swap */
}

/* If you have a title or other text within .project-img-box */
.project-title {
  bottom: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  color: white; /* Choose a color that stands out over your images */
  z-index: 2; /* Ensures the title appears above the images */
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  font-family:'Permanent Marker', cursive;
  padding-left: 12px;
}
/* Project.css */
.project-container {
  position: relative;
  /* ... */
  width: auto;
  margin-bottom: 20px;
  opacity: 0;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 768px) {
  .project-img-box {
    width: 100%;
    height: auto;
    margin-bottom: 20px; /* Add some space below the image */
  }
  .additional-content a {
    display: block; /* Stack the links */
    margin-bottom: 10px; /* Add space between links */
  }
  /* Ensure that overflow is not hiding content */
  .parent-of-project-container {
    overflow: visible;
    height: auto; /* Or sufficient height */
  }
}

/* Continue with existing styles... */
.btn .bi-github {
  padding: .25em; /* Increase the padding to give more space */
  fill: #ffffff; /* Explicitly set the SVG fill color to white, for example */
}

.btn .bi {
  font-size: 1.5em; /* Example size, adjust as needed */
}

.btn-primary {
  display: none;
  background-image: linear-gradient(to right, #5666f5, #7ac5f7); /* Blue gradient */
  border: none; /* Remove border for a cleaner look */
  border-radius: 16px; /* Fully rounded corners */
  color: white; /* Text color */
  padding: 0.5em 1em; /* Top & Bottom | Left & Right padding */
  transition: all 0.5s ease; /* Smooth transition for hover effects */
  border: 1px solid #c2cfea; /* Light border for a subtle edge */
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15), -4px -4px 10px rgba(255, 255, 255, 0.7);
}
/* Media query for screens smaller than 600px */
@media (max-width: 600px) {
  .btn-primary {
    padding: 0.25em 0.5em; /* Smaller padding for smaller screens */
    font-size: 0.8em; /* Smaller font size for smaller screens */
  }
}

.btn-primary .bi-github {
  margin-right: 0.5em; /* Space between icon and text */
}

.btn-primary:hover {
  background-image: linear-gradient(to right, #0056b3, #3399FF); /* Darker gradient on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Larger shadow on hover */
  transform: translateY(-2px); /* Slight raise on hover */
}

/* Define the keyframes for the animations */
@keyframes slideFromBottom {
  from { transform: translateY(100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideFromTop {
  from { transform: translateY(-100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Apply the animations to each project container */
.project-container:nth-child(1) {
  animation: slideFromBottom 0.8s ease-out forwards;
  animation-delay: .25s;
}

.project-container:nth-child(2) {
  animation: slideFromTop 0.8s ease-out forwards;
  animation-delay: 0.5s; /* Delay to sequence the animation after the first */
}

.project-container:nth-child(3) {
  animation: slideFromBottom 0.8s ease-out forwards;
  animation-delay: .75s; /* Delay to sequence the animation after the second */
}

@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

/* This media query is for devices with a width less than or equal to 768px,
   which typically includes tablets and mobile devices in portrait orientation */
   @media only screen and (max-width: 768px) {
    .project-container {
      width: 55%; /* Set the width of the project containers to 70% */
      margin: 0 auto; /* Center the containers in the viewport */
      /* Adjust padding, margins, and other properties as needed */
    }
  
    /* Ensure the image within the project container is also responsive */
    .project-img-box {
      width: 100%; /* Make the image container take the full width of the project container */
      height: auto; /* Maintain the aspect ratio of the content */
    }
  
    .project-image {
      max-width: 100%; /* Make the image responsive */
      height: auto; /* Maintain the aspect ratio of the image */
    }
    .btn btn-primary {
      display: none;
    }
    .btn-primary {
      display: none;
      
    }
    /* Add any additional styles you need to adjust for this viewport size */
  }