.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flexbox;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;
    box-sizing: content-box;
    z-index: 1000;
    text-align: center;
    backdrop-filter: blur(5px);
}

.overlay h1 {
    font-size: 4em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: slide-in 1s ease-out forwards;
}

.overlay p {
    font-size: 1.5em;
    max-width: auto;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    opacity: 0;
    animation: fade-in 2s 1s forwards;
}




.overlay1 {
    display: flex; 
    flex-direction: column; 
    justify-content:center; 
    align-items: center; 
    height: 100vh; 
    width: 100%; 
    position: absolute; 
    top: 0; 
    left: 0; 
    background-color: #f5f5f5;
    text-align: center; 
  }
  .overlay1 h1 {
    margin-bottom: 2rem;
    font-size: 4em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: slide-in 1s ease-out forwards;
  }

  .spinner {
    display: block;
    width: 2rem;
    height: 2rem;
    border: 2px solid #333;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: relative;
    font-size: 2rem;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}
  
  .overlay .arrow {
    position: relative;
    font-size: 2rem;
  }
  
  .overlay .arrow span {
    display: block;
    width: 2rem;
    height: 2rem;
    border-bottom: 2px solid #333;
    border-right: 2px solid #333;
    transform: rotate(45deg);
    margin: -10px;
    animation: arrow 2s infinite;
  }
  
  .overlay .arrow span:nth-child(1) {
    animation-delay: -0.4s;
}

.overlay .arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.overlay .arrow span:nth-child(3) {
    animation-delay: 0s;
}
  @keyframes arrow {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

@keyframes slide-in {
    0% { transform: translateY(-50px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes slide-up {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

